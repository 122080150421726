//
//
// user-variables.scss
//
// Place your own variable overrides here, these will override any Bootstrap and theme variables.

//*-----------------------------------------------
//|   Solid Colors
//-----------------------------------------------*/
$blue: #2294dd;

//*-----------------------------------------------
//|   Color System
//-----------------------------------------------*/
$white: #fff;
$gray-100: #f2f5f7;
$gray-200: #e9eef1;
$gray-300: #f1f1f2;
$gray-400: #c3cddd;
$gray-500: #a3b5cd;
$gray-600: #6284a6;
$gray-700: #4f708f;
$gray-800: #4f6881;
$gray-900: #454b5a;
$gray-1000: #363c44;
$gray-1100: #27292e;
$black: #000;

//*-----------------------------------------------
//|   Theme Colors
//-----------------------------------------------*/
$primary: $blue;

//*-----------------------------------------------
//|   Fonts Families
//-----------------------------------------------*/
$font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
  'Segoe UI Symbol';
$font-family-monospace: 'SFMono-Regular', Menlo, Monaco, Consolas,
  'Liberation Mono', 'Courier New', monospace;
$font-family-base: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
  'Segoe UI Symbol';

//*-----------------------------------------------
//|   Components
//-----------------------------------------------*/
$border-radius: 0.35rem;
$border-radius-soft: 0.7rem;
$border-radius-capsule: 4.125rem;

//*-----------------------------------------------
//|   Fonts
//-----------------------------------------------*/
$headings-color: $black;
:root,
:root.light,
:root .light {
  --falcon-muted: #7b808d !important;
  --falcon-popover-peader-color: $black !important;
}

//*-----------------------------------------------
//|   Navbar Vertical
//-----------------------------------------------*/
$vertical-navbar-padding: 1.8rem;

//*-----------------------------------------------
//|   Body
//-----------------------------------------------*/
$body-bg: $gray-200;
$body-color: $black;
$font-weight-bold: 700;
$headings-font-weight: $font-weight-bold;

//*-----------------------------------------------
//|   Cards
//-----------------------------------------------*/
$card-color: $black;

//*-----------------------------------------------
//|   Forms
//-----------------------------------------------*/
$input-bg: $gray-100;

//*-----------------------------------------------
//|   Shadow
//-----------------------------------------------*/
$box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08), 0 1px 2px 0 rgba(0, 0, 0, 0.08);

/*-----------------------------------------------
|  Toast
-----------------------------------------------*/

.toast {
  border-radius: $border-radius-soft; // 0.8rem !important;
  max-width: 95% !important;
}

.toast-header {
  border-top-left-radius: $border-radius-soft;
  border-top-right-radius: $border-radius-soft;
}
