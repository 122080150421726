.removecaret.dropdown-toggle::after {
  display: none;
}

.btn-no-shadow:focus {
  box-shadow: none !important;
}

.btn-no-boarders {
  border-color: transparent !important;
}
